import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Timeline from "react-image-timeline"
import "./timeline.scss"
require("react-image-timeline/dist/timeline.css")

const CustomTopLabel = props => {
  return <div className="custom-toplabel">1981</div>
}

const CustomBottomLabel = props => {
  return <div className="custom-bottomlabel">2019</div>
}

// A custom header to replace the default
const CustomHeader = props => {
  // The corresponding "event" model
  const { title, displayDate } = props.event

  // Use your own CSS
  return (
    <div className="custom-header">
      <h1 className="font-header text-3xl">{title}</h1>
      <p className="font-header text-xl">{displayDate}</p>
    </div>
  )
}

const CustomTextBody = props => {
  const { text } = props.event
  return (
    <div className="custom-textBody">
      <p className="font-body text-gray-800">{text}</p>
    </div>
  )
}

// remove area below image
const CustomFooter = () => {
  return <div className="custom-footer"></div>
}

const events = [
  {
    date: new Date(1981, 0, 1),
    displayDate: "1981",
    title: "Compensated Nut Patent Awarded",
    text:
      "To address the age-old problem of fretted instruments not playing in tune at the nut-end of the neck, Paul Reed Smith invented a compensated nut to improve intonation throughout the fretboard. ",
    imageUrl: "https://35timeline.s3.amazonaws.com/1-paul.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1984, 0, 2),
    displayDate: "1984",
    title: "Tremolo Patent Awarded",
    text:
      "After Carlos Santana requested a guitar with a vibrato that stayed in tune, Paul Reed Smith began his quest of creating the currently patented PRS tremolo system with the help of John Mann.",
    imageUrl: "https://35timeline.s3.amazonaws.com/2-trem-patent.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1984, 0, 3),
    displayDate: "1984",
    title: "First Orders",
    text:
      "In 1984, Paul set out on a tour of East Coast guitar dealers armed with two prototypes (pictured). Sam Ash placed the first order with Paul for 30 guitars on this trip, bringing value to the company that would later help secure official investors.",
    imageUrl: "https://35timeline.s3.amazonaws.com/3-first-orders.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1985, 0, 1),
    displayDate: "1985",
    title: "Open For Business",
    text:
      "After attending his first winter NAMM show in January of 1985 with a small selection of guitars, Smith returned to Annapolis, Maryland and moved into the PRS Virginia Ave factory. For four months, a small team of fewer than 15 employees began building 20 un-serialized guitars.",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/4-open-for-business.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1985, 0, 2),
    displayDate: "1985",
    title: "PRS-Made Pickups Debut",
    text:
      "In order to have full autonomy of the tone of the instruments he was building, Paul Reed Smith developed the PRS Standard Treble and Bass Pickups in 1985. In 2015, these pickups were named one of the “33 Biggest Innovations in Pickup Design” by Guitar Player Magazine. ",
    imageUrl: "https://35timeline.s3.amazonaws.com/5-prs-pickups.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1986, 0, 1),
    displayDate: "1986",
    title: "1000th Guitar Made",
    text:
      'The very first production serial-numbered instrument came off the line in August 1985. On June 27th, 1986 PRS celebrated "The Night of the 1,000 Guitar Party." PRS Guitars was gaining momentum.',
    imageUrl:
      "https://35timeline.s3.amazonaws.com/6-1000th-guitar.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1992, 0, 1),
    displayDate: "1992",
    title: "Double-Action Truss Rod",
    text:
      "In 1992 the truss rod in a PRS neck was changed from a single-action to a double-action style. With the ability to correct both bowed and flexed necks, the double-action truss rod not only allowed for increased adjustability, it also helped create an even more stable neck.",
    imageUrl: "https://35timeline.s3.amazonaws.com/8-trussrod.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1992, 0, 2),
    displayDate: "1992",
    title: "Dragon 1",
    text:
      'When Paul Smith was 16, he dreamt about a guitar with a dragon inlaid down the neck. 20 years later, the first PRS Dragon guitar was born. With a list price of $8,000 it was an ultra-high-end "collector" guitar.',
    imageUrl: "https://35timeline.s3.amazonaws.com/9-dragon.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1993, 0, 1),
    displayDate: "1993/94",
    title: "Step into the Vintage Market (22 frets/wide fat)",
    text:
      "In 1993 and 1994, PRS introduced the Custom 22 and McCarty Models (respectively). Paul believed that the shorter neck provided a fatter, more vintage tone, and with the McCarty model he was paying tribute to his teacher, Ted McCarty, a master of the vintage market.",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/10-vintage-mccarty.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1995, 0, 1),
    displayDate: "1995",
    title: "First CNC Machine",
    text:
      'CNC ("Computer Numerical Control") machines are computer-driven millers that help ensure a higher level of quality by providing more accurate and repeatable operations. While PRS Guitars are still hand sanded, assembled, and quality inspected, the CNC machine allowed for the perfect body and neck carve without the margin for human error.',
    imageUrl: "https://35timeline.s3.amazonaws.com/7-cnc.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1996, 0, 1),
    displayDate: "1996",
    title: "Move to Kent Island",
    text:
      "After outgrowing the original Virginia Avenue shop, PRS Guitars moved to a new building across the Chesapeake Bay on Kent Island. With 20,000 square feet of space, the new facility provided a more mature manufacturing space and workflow for PRS’s growing family of luthiers.",
    imageUrl: "https://35timeline.s3.amazonaws.com/11-kent-island.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1996, 0, 2),
    displayDate: "1996",
    title: "Private Stock Introduced",
    text:
      'Based off of the 1995 "Guitars of the Month" program, Paul Smith, Joe Knaggs, and a small team of talented luthiers began building custom guitars for musicians under the Private Stock program. The Private Stock team remains small and highly talented, constantly striving to create the best guitars money can buy.',
    imageUrl:
      "https://35timeline.s3.amazonaws.com/12-private-stock-introduced.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1996, 0, 3),
    displayDate: "1996",
    title: "PRS Guitar added to Smithsonian Collection – Washington, DC",
    text:
      "When the Smithsonian National Museum of American History in Washington DC assembled more than a dozen guitars to exemplify the role of the instrument in American popular music, a PRS Dragon I was proudly included in the exhibit. This marks the first of many PRS guitars to be displayed in museums across the nation.",
    imageUrl: "https://35timeline.s3.amazonaws.com/13-smithsonian.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1997, 0, 1),
    displayDate: "1997",
    title: "PRS Hits 100 Employees ",
    text:
      "As PRS grew in the late 90's, PRS hired its 100th employee. Today, PRS’s headcount hovers around 355 employees, covering a broad spectrum of important roles, including production, R&D, sales, marketing, artist relations, customer service, supply chain, and more.",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/14-100-employees.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(1997, 0, 2),
    displayDate: "1997",
    title: "PRS McCarty on HRC Baltimore ",
    text:
      "In 1997, a 65-foot tall PRS McCarty model was placed atop the Hard Rock Cafe in Baltimore Maryland’s Inner Harbor. ",
    imageUrl: "https://35timeline.s3.amazonaws.com/15-hardrock.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2000, 0, 1),
    displayDate: "2000",
    title: "Piezo Patent Granted",
    text:
      "PRS Guitars introduced the first piezo equipped guitar in 1998. After customizing the system with LR Baggs, the design was granted a US patent in 1999. The LR Baggs / PRS Piezo system provides musicians with both beautiful acoustic guitar tones and electric guitar tones in one instrument.",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/16-piezo-patent.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2000, 0, 3),
    displayDate: "2000",
    title: "PRS Receives MIPA award for Singlecut",
    text:
      "In 2000, PRS received its first Musikmesse International Press Awards (mipa) for “Best Electric Guitar.” Press from all over the world vote for this distinguished honor each year and winners are announced at the Musikmesse / Prolight + Sound trade show in Frankfurt, Germany. As of 2019, PRS has received the “Best Electric Guitar of the Year” accolade nine times.",
    imageUrl: "https://35timeline.s3.amazonaws.com/17-mipa.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2001, 0, 1),
    displayDate: "2001",
    title: "SE Line Introduced",
    text:
      "The SE line began in 2001, made with the goal of providing an affordable instrument with the high-quality and dependability players love from PRS. Carlos Santana played a large role in bringing the project online and the Santana SE was the first model to be introduced.",
    imageUrl: "https://35timeline.s3.amazonaws.com/18-se-santana.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2005, 0, 1),
    displayDate: "2005",
    title: "PRS Wins Lawsuit – Singlecut Returns to the Line",
    text:
      "In September 2005, the Sixth Circuit Court of Appeals reversed a lower court decision, ordering the dismissal of Gibson’s suit against PRS. After appeal, the United States Supreme Court decision ended Gibson's multi-year effort to thwart legitimate competition under the guise of intellectual property law. PRS immediately resumed production of the PRS Singlecut. ",
    imageUrl: "https://35timeline.s3.amazonaws.com/19-singlecut.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2007, 0, 1),
    displayDate: "2007",
    title: "First Experience PRS Event Held at PRS HQ",
    text:
      "With the goal of celebrating the company and showing appreciation for the thousands of PRS fans and players around the world, PRS held its first free public multi-day “Experience” event consisting of factory tours, guitar demos, and live music performances.",
    imageUrl: "https://35timeline.s3.amazonaws.com/20-experience.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2008, 0, 1),
    displayDate: "2008",
    title: "Stevensville, MD Expansion Opens",
    text:
      "As PRS sales and production grew, additional factory space and equipment was needed yet again in order to keep up with demand. Groundbreaking began in 2006 and in 2008 phase two of PRS Guitars' Stevensville factory was completed. The new building added 90,000 sq feet of much-needed production and office space. ",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/21-stevensville-expansion.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2009, 0, 1),
    displayDate: "2009",
    title: "PRS Introduced PRS Acoustics",
    text:
      "After years of designing and prototyping, the Angelus Cutaway and Tonare Grand acoustic models were introduced at Winter NAMM 2009 and began full production later that year in PRS Guitars’ recently expanded manufacturing facility.",
    imageUrl: "https://35timeline.s3.amazonaws.com/22-acoustics.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2009, 0, 2),
    displayDate: "2009",
    title: "PRS Introduced PRS Amplifiers",
    text:
      "After nearly four years of close collaboration with master amp designer Doug Sewell, PRS Guitars introduced their highly anticipated all-tube amp line at Winter NAMM 2009. Sewell and Smith worked together to produce a line that covers the needs of varied playing styles while providing some uniquely different tonal possibilities. ",
    imageUrl: "https://35timeline.s3.amazonaws.com/23-amps.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2009, 0, 3),
    displayDate: "2009",
    title: "PRS Introduces the PTC",
    text:
      "In 2009 PRS selected a few of their most experienced luthiers and guitar technicians to create the only authorized PRS service and repair center, known as the PTC. Over the years, the PTC has seen and repaired hundreds of guitars, from tragic tales of headstocks being knocked off, to simple setups, re-frets, and hardware upgrades.",
    imageUrl: "https://35timeline.s3.amazonaws.com/24-ptc.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2009, 0, 4),
    displayDate: "2009",
    title: '"Artist Grade" Wood Library Opens',
    text:
      "2009 marks the introduction of the “Artist Grade” Wood Library – a collection of highly-figured maple tops set aside for dealers to handpick for their orders. Eventually, this led to the Private Stock Vault, which houses PRS’s finest collection of exotic woods and inlay materials.",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/25-wood-library.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2010, 0, 1),
    displayDate: "2010",
    title:
      "Paul Reed Smith Awarded Vintage Guitar Innovator Hall of Fame Award",
    text:
      "Paul Reed Smith was inducted into Vintage Guitar Magazine’s prestigious Hall of Fame, joining past inductees Leo Fender, Les Paul, and Ted McCarty. Vintage Guitar publisher Alan Greenwood noted, “Paul is the first of his generation of guitar builders to be inducted to the Vintage Guitar Hall of Fame, his entry marks a changing of the guard.”",
    imageUrl: "https://35timeline.s3.amazonaws.com/26-paul-award.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2013, 0, 1),
    displayDate: "2013",
    title: "PRS Introduces PRS S2 Series",
    text:
      "After a lengthy R&D process, the S2 Series was introduced in 2013 and aimed to fill the gap between PRS’ affordable line of SE instruments and the renowned US core line. The American-made line of guitars has grown from three instruments to more than a dozen and caters to a new breed of player.",
    imageUrl: "https://35timeline.s3.amazonaws.com/27-s2-series.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2013, 0, 2),
    displayDate: "2013",
    title:
      "PRS Electric and Acoustic Guitar Added to The Met’s Permanent Collection",
    text:
      "On October 7th, 2013, The Metropolitan Museum of Art in New York accepted a Paul Reed Smith electric guitar, Acoustic Steel String guitar, and Acoustic Classical Nylon String guitar into its permanent collection. The presentation of the electric guitar included a public performance by PRS Artist and world-renowned guitarist John McLaughlin.",
    imageUrl: "https://35timeline.s3.amazonaws.com/28-the-met.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2015, 0, 1),
    displayDate: "2015",
    title: "PRS Reopens Public Factory Tours",
    text:
      "In celebration of its 30th Anniversary, PRS reopened factory tours inside their Maryland headquarters, showcasing the production of its electric, acoustic, and amplifier lineups.",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/29-factory-tours.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2017, 0, 1),
    displayDate: "2017",
    title: "McCarty 594 Another Foray into the Vintage Market",
    text:
      "The McCarty 594 was introduced with the goal of offering a vintage-sounding instrument with the reliability of modern-day manufacturing. For the first time in production, the 594 offered a 3-way toggle on the upper bout of the double-cutaway PRS body shape, a 24.594” scale length, and introduced 58/15 LT pickups.",
    imageUrl: "https://35timeline.s3.amazonaws.com/30-mccarty-594.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2018, 0, 1),
    displayDate: "2018",
    title: "Silver Sky Makes Waves with Single Coils",
    text:
      "After years of development and collaboration with John Mayer, PRS introduced the Silver Sky, a three single-coil guitar inspired by Mayer and Smith’s favorite 1963 and 1964 instruments. The launch created waves in the guitar community and traffic unexpectedly crashed the PRS website for a brief period on launch day.",
    imageUrl: "https://35timeline.s3.amazonaws.com/31-silver-sky.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2018, 0, 1),
    displayDate: "2018",
    title: "TCI Pickups Begin",
    text:
      "“TCI,” meaning Tuned Capacitance and Inductance, was a technology revelation discovered in the research and design phase of the Silver Sky’s 635JM pickups. More than a pickup, TCI is a process that allows pickups to be fine-tuned to sound exactly as desired. As part of the 35th Anniversary celebration, PRS house-wound pickups have all been tweaked with TCI.",
    imageUrl: "https://35timeline.s3.amazonaws.com/32-tci-pickups.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2019, 0, 1),
    displayDate: "2019",
    title: "PRS Hits 350 Employees",
    text:
      "As demand for PRS Guitars and amplifiers increased, new employees were trained and hired bringing the PRS headcount to over 350 employees for the first time. ",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/33-350-employees.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2019, 0, 2),
    displayDate: "2019",
    title: "Dedicated SE factory Opens",
    text:
      "2019 saw the opening of a dedicated facility in Surabaya, Indonesia where employees focus on building only SE instruments to PRS’s exacting specifications.",
    imageUrl: "https://35timeline.s3.amazonaws.com/34-se-factory.jpg?raw=true",
    noButton: "test",
  },
  {
    date: new Date(2019, 0, 3),
    displayDate: "2019",
    title: "Proprietary String Sales Begin ",
    text:
      "PRS’s Maryland electric guitar production switched over to a new proprietary lineup of Signature Series strings, complementing the existing classic series strings which were used on PRS Guitars for more than 30 years. Signature Series strings feature a reformulated alloy for louder and brighter tone as well as soldered ends for extended string life and durability. ",
    imageUrl:
      "https://35timeline.s3.amazonaws.com/35-proprietary-strings-sales.jpg?raw=true",
    noButton: "test",
  },
]

const TimelinePage = () => (
  <Layout>
    <SEO title="Timeline" />
    <h2 className="pt-40 pb-16 text-center font-header text-5xl ">
      35 Years: A Timeline
    </h2>
    <Timeline
      events={events}
      customComponents={{
        topLabel: CustomTopLabel,
        header: CustomHeader,
        footer: CustomFooter,
        textBody: CustomTextBody,
        bottomLabel: CustomBottomLabel,
      }}
    />
  </Layout>
)

export default TimelinePage
